// 开发环境域名

// const host_development = "https://likeshopb2cplusdoukai.yixiangonline.com";
// const host_development = "https://shoptest.uscorp.cn";
const host_development = 'https://ok.zhongduyun.com'

export default {
  // 版本
  version: '1.9.0',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
